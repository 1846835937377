<template>
  <!-- Banner2 Load 動畫 -->
    <Load></Load>
  <!-- Banner2 Load 動畫 end -->
  <Banner></Banner>
  <!-- Banner2 啟用時要調整PokerBlock.vue的poker_area css -->
  <!-- <Banner2></Banner2> -->
  <!-- Banner2 end -->
  <div class="section web_section">
    <div class="container">
      <div class="row">
        <div class="col-12 web_title">
          <h2>{{ $store.state.menuTitletwo }}</h2>
          <p class="en_title">{{ $store.state.webEntitle }}</p>
        </div>
        <div class="col-12">
          <div class="col-xl-6 col-12 circle_bg">
          <div class="colorcard slide-move">
            <img src="@/assets/colorcard.svg" />
          </div>
          <div class="computer"><img src="@/assets/computer.svg" /></div>
          <div class="images slide-top"><img src="@/assets/images.svg" /></div>
          <div class="circle circleimg"><img src="@/assets/circle.svg" /></div>
          <div class="circle_txt">RWD</div>
        </div>
        </div>
        <div class="col-12 web_txt">
          <p>
            網站是否很久沒更新了呢？想要客製化設計又找不到滿意的設計公司嗎？思倍傑資訊提供RWD客製化網站服務，除了完善的網站功能外，還有獨一無二的設計編排，歡迎與我們談談！
          </p>
          <div class="more_block">
            <router-link to="/webdesign" class="more_btn">更多案例</router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
  <section class="web_Bg">
    <div class="container mt-5">
      <div class="row">
        <router-link to="/webdesign" class="design_linkbox" @click="toguid('/webdesign','webdesign')">
          <div class="design_box">
            <img src="@/assets/design_img.jpg">
          </div>
          <p>RWD客製化網站</p>
        </router-link>
        <router-link to="/ESG" class="design_linkbox" @click="toguid('/ESG','ESG')">
          <div class="design_box">
            <img src="@/assets/design_img2.jpg">
          </div>
          <p>ESG/CSR網站建置</p>
        </router-link>
        <router-link to="/bizweb" class="design_linkbox" @click="toguid('/bizweb','bizweb')">
          <div class="design_box">
            <img src="@/assets/design_img3.jpg">
          </div>
          <p>好架網</p>
          <div class="design_memo">
            <p><span>免費試用至<span class="bigDate">12/31</span></span><br>
              快來建置自己的網站！</p>
            <!-- <p>快速輕鬆建置自己的網站！</p> -->
          </div>
        </router-link>
      </div>
    </div>
  </section>
  <div id="skroll">
  <div class="section swier_bg" data-anchor-target="#skroll" data-center-top="transform:scale(0.85);" data-bottom="transform:scale(1);">
    <div class="container">
      <div class="row">
       <div class="col-12 swier_txt">
       <div class="row align-items-center">
        <div class="col-lg-5 col-12 mb-5">
          <h2 class="text-light">{{ $store.state.menuTitleThree }}</h2>
          <p class="en_title text-light">SOFTWARE</p>
        </div>
        <div class="col-lg-7 col-12 mb-5">
          <p class="text-light">
            思倍傑擁有豐富的軟體開發經驗，依照客戶需求，打造不同系統功能，包括EIP企業入口、環安衛系統、人力資源系統...，推動企業e化，提供使用者更便利的操作環境。
          </p>
        </div>
       </div>
       </div>
       <div class="col-12 swier_block d-flex">
        <router-link to="/system" class="swier_box tab1" id="bg1" @click="toguid('/system','system')">
          響應式EIP企業入口
          </router-link>
          <router-link to="/system" class="swier_box tab2" id="bg2" @click="toguid('/system','enterprise')">
            環安衛系統
          </router-link>
          <router-link to="/system" class="swier_box tab3" id="bg3" @click="toguid('/system','commerce')">
            電子商務系統
          </router-link>
          <router-link to="/system" class="swier_box tab4" id="bg4" @click="toguid('/system','enterprise')">
            企業E化專案
          </router-link>
       </div>
       <div class="col-12 d-flex justify-content-center">
        <div class="soft_imgBg">
            <img src="@/assets/soft_img.png" id="soft_img">
          </div>
       </div>
      </div>
    </div>
  </div>
</div>

  <div class="section cus_soft_bg">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-12 mt-4 main_title">
          <h2>客製化系統開發</h2>
        </div>
        <div class="col-xl-8 col-12 mt-4">
          <p>資料管理系統資料是作業的泉源，也是執行的法則。沒有經過設計的系統，不但會降低使用者的使用意願，還會影響資料作業。因此建構出一個容易操作的管理介面，對我們來說是一個很重要的責任。</p>
        </div>
        <div class="col-2">
        </div>
        <div class="col-lg-10 col-12 cus_soft_block">
          <div class="row">
            <div class="col-xl-3 col-md-6 col-12 cus_soft_box">
             <div class="cus_soft_img">
              <i class="icon-computer"></i>
             </div>
              <p class="title">環安衛系統</p>
              <p>符合TOSHMS驗證規範與指導綱領本系統化工具管理平台，提供網站供各級環工人員上傳、下載相關檔案格式(含手冊、文件程序與表單範例)，並具通知功能以達協同作業目標。</p>
            </div>
            <div class="col-xl-3 col-md-6 col-12 cus_soft_box">
             <div class="cus_soft_img">
              <i class="icon-data"></i>
             </div>
              <p class="title">資料管理系統</p>
              <p>資料是作業的泉源，也是執行的法則。沒有經過設計的系統，會降低了使用者的使用意願。因此建構出一個容易操作的管理介面，對我們來說是一個很重要的責任。</p>
            </div>
            <div class="col-xl-3 col-md-6 col-12 cus_soft_box">
             <div class="cus_soft_img">
              <i class="icon-computers"></i>
             </div>
              <p class="title">電子商務專案</p>
              <p>主要功能為底價製作、選商、詢價、決標、驗收及核簽等作業，與協力廠商連線作業，直接至web站投標，線上開標，簡化行政作業，提昇管理績效。</p>
            </div>
            <div class="col-xl-3 col-md-6 col-12 cus_soft_box">
             <div class="cus_soft_img">
              <i class="icon-cloud"></i>
             </div>
              <p class="title">網頁稽催系統</p>
              <p>主要功能為上稿審查、資料更新速率、資料時效控制及定期稽催，並結合人事系統，職員異動不影響網站維護內容的品質與效率。</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- <div class="section ach_bg his_section">
    <div class="container-fuild">
      <div class="row ach_flex">
        <div class="col-12 his_title_block">
          <h2>年度成就</h2>
          <p class="en_title">ACHIEVEMENT</p>
          <p class="mt-5 mb-5">2022年台灣企業永續獎獲獎名單</p>
        </div>
        <div class="col-xl-4 col-12 his_img_block">
          <img src="@/assets/ach_img.png" class="ach_img" />
        </div>
        <div class="col-xxl-6 col-xl-8 col-12 his_txt_block">
          <div class="row">
            <div class="his_box">
              <img src="@/assets/his_csc_icon.jpg" />
              <div>
                <p class="his_title">中國鋼鐵公司</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 白金獎<br>十大永續典範企業獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_chc_icon.jpg" />
              <div>
                <p class="his_title">中聯資源股份有限公司</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 白金獎</p>
              </div>
            </div>

            <div class="his_box">
              <img src="@/assets/his_cscc_icon.jpg" />
              <div>
                <p class="his_title">中鋼碳素</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 金獎</p>
              </div>
            </div>

            <div class="his_box">
              <img src="@/assets/his_chs_icon.jpg" />
              <div>
                <p class="his_title">中鴻鋼鐵股份有限公司</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 金獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_hks_icon.jpg" />
              <div>
                <p class="his_title">新光鋼鐵</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 銅獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_csac_icon.jpg" />
              <div>
                <p class="his_title">中鋼鋁業股份有限公司</p>
                <span>CSR網站 / 網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 銅獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_DG_icon.jpg" />
              <div>
                <p class="his_title">中龍鋼鐵</p>
                <span>網站規劃 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 銅獎</p>
              </div>
            </div>
          </div>
        </div>
        <div class="more_block">
          <router-link to="/ESG" class="more_btn">更多案例</router-link>
        </div>
      </div>
    </div>
  </div> -->
  <div class="section ach_bg his_section">
    <div class="container-fuild">
      <div class="row ach_flex">
        <div class="col-12 his_title_block">
          <h2>年度成就</h2>
          <p class="en_title">ACHIEVEMENT</p>
          <p class="mt-5 mb-5">2024年台灣企業永續獎獲獎名單</p>
        </div>
        <div class="col-xl-4 col-12 his_img_block">
          <img src="@/assets/ach_img.png" class="ach_img" />
        </div>
        <div class="col-xxl-6 col-xl-8 col-12 his_txt_block">
          <div class="row">
            <div class="his_box">
              <img src="@/assets/his_csc_icon.jpg" />
              <div>
                <p class="his_title">中國鋼鐵公司</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 白金獎<br>十大永續典範企業獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_hks_icon.jpg" />
              <div>
                <p class="his_title">新光鋼鐵</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 白金獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_chc_icon.jpg" />
              <div>
                <p class="his_title">中聯資源股份有限公司</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 金獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_chs_icon.jpg" />
              <div>
                <p class="his_title">中鴻鋼鐵股份有限公司</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 金獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_cscc_icon.jpg" />
              <div>
                <p class="his_title">中鋼碳素</p>
                <span>CSR網站 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 金獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_csac_icon.jpg" />
              <div>
                <p class="his_title">中鋼鋁業股份有限公司</p>
                <span>CSR網站 / 網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 銀獎</p>
              </div>
            </div>
            <div class="his_box">
              <img src="@/assets/his_DG_icon.jpg" />
              <div>
                <p class="his_title">中龍鋼鐵</p>
                <span>網站規劃 / 響應式網頁設計</span>
                <p class="mt-3">榮獲 傳統製造業 銅獎</p>
              </div>
            </div>
          </div>
        </div>
        <div class="more_block">
          <router-link to="/ESG" class="more_btn">更多案例</router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- ---------------------------- -->
  <div class="section case_section">
    <div class="container">
      <div class="row">
        <div class="col-12 index_title">
          <h2>成功案例</h2>
          <p class="en_title">CASE STUDY</p>
        </div>
        <div class="mt-5 logo_block">
          <div class="logo_box" v-for="item in caseItems" :key="item.id">
            <a :href="item.link" target="_blank"><img :src="item.img" /></a>
          </div>
        </div>

        <div class="more_block">
          <router-link
            to="/webdesign"
            @click="toguid('/webdesign', 'All')"
            class="more_btn"
            >更多案例</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <AllFooter></AllFooter>
</template>

<script>
import Banner from '@/components/HomeBanner.vue'
// import Banner2 from '@/components/HomeBanner2.vue'
// import Load from '@/components/LoadAnimate.vue'
import $ from 'jquery'

export default {
  name: 'HomeView',
  components: { Banner },
  data () {
    return {
      caseItems: []
    }
  },
  created () {
    if (localStorage.getItem('HomeReload') === null || localStorage.getItem('HomeReload') === 'True') {
      localStorage.setItem('HomeReload', 'False')
      window.location.reload()
    } else {
      localStorage.setItem('HomeReload', 'True')
    }
    const WebApi =
      'https://www.spadej.com.tw/Back/Handler/H_Logo.ashx'
    this.$http.get(WebApi).then((response) => {
      this.caseItems = response.data.caseItems
    })
  },
  methods: {
    toguid (path, id) {
      /* eslint-disable */
      // var path = path
      const Id = id
      localStorage.setItem('toId', Id)
      this.$router.push(path)
      localStorage.setItem('Reload', 'True')
    },
    // 動畫定時消失
    loadingclose(){
      setTimeout(function() {
        $('#opening_animate').fadeOut(200)
    }, 8500);
    }
  },
  mounted(){
    $('.opening_skip').click(function () {
      $('#opening_animate').fadeOut(200)
    })
    $(document).ready(function(){
  $(".swier_box").hover(function(){
    $('#soft_img').addClass(this.id);
    }, function(){
    $('#soft_img').removeClass(this.id);
  });
    });
    var scrollObj = skrollr.init({
  //跟smoothScrolling的功能，主要都是讓scroll事件不要這麼敏感，動畫才不會看起來卡卡的。
  smoothScrolling : true,
  smoothScrollingDuration:200,

  //可以調整ScrollBar往下拉對應到keyframe的值等倍放大
  scale:1,

  //讓文本高度自動達到滿足Keyframe的條件
  forceHeight:false,

  //針對行動裝置的功能
  mobileCheck:function(){},
  mobileDeceleration:0.004,
});
  }
};
</script>
<style lang="scss" scoped>
@import "../css/animate.scss";



.web_section {
  margin-top: 3rem;
  h2,.en_title {
    color: var(--web_color);
  }
  .row{
    position: relative;
  }
}
.soft_section {
  h2,.en_title {
    color: var(--soft_color);
  }
}
.his_section {
  h2,.en_title {
    color: var(--his_color);
  }
}
.case_section {
  h2,.en_title {
    color: var(--case_color);
  }
}
h3 {
  margin: 2rem 0;
}

.poker_img img {
  width: 100%;
}
.title_block {
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 3rem;
  }
}

//SVG動畫
.circle_bg {
  height: 100%;
  min-height: 470px;
  position: relative;
  margin: 5.5rem auto 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: url("@/assets/computer_bg.svg") no-repeat;
    position: absolute;
    bottom: -9%;
    right: 50%;
    transform: translateX(50%);
  }
  .computer {
    width: 60%;
    position: absolute;
    top: 30px;
    right: 50%;
    transform: translateX(50%);
  }
  .circle {
    width: 20%;
    position: absolute;
    bottom: 20%;
    left: 0%;
  }
  .circle_txt {
    position: absolute;
    bottom: 31%;
    left: 15.5%;
    font-size: 1.75rem;
    color: #fff;
  }
  .colorcard {
    width: 30%;
    position: absolute;
    top: 0;
    left: 30px;
  }
  .images {
    width: 30%;
    position: absolute;
    top: -10%;
    right: 25%;
  }
}
.web_txt{
  position: absolute;
    right: 0;
    width: 32%;
    bottom: 35%;
    padding: 3rem 2rem 3rem 4rem;
    background: url(@/assets/web_txtBg.png) center center no-repeat;
    background-size: 100%;
    .more_block{
      margin-top: 1rem !important;
    }
}
.web_title{
  position: absolute;
  .en_title{
    width: 30%;
  }
}
// 網站設計區塊
.web_Bg{
  position: relative;
  margin-bottom: 7rem;
  .container{
    position: relative;
    .row{
      justify-content: space-between;
    }
    &::before{
    content: "WEB DESIGN";
    font-family: 'Aclonica', sans-serif;
    color: #f2f2f2;
    position: absolute;
    bottom: 0rem;
    left: 30%;
    font-size: 8rem;
    z-index: -1;
    }
  }
  &::before{
    content: "WEB DESIGN";
    font-family: 'Gotu', sans-serif;
    color: #f2f2f2;
    position: absolute;
    top: 2rem;
    left: 0;
    font-size: 8rem;
    z-index: -1;
  }
  &::after{
    content: "WEB DESIGN";
    font-family: 'Josefin Sans', sans-serif;
    color: #f2f2f2;
    position: absolute;
    top: 16rem;
    right: 0;
    font-size: 8rem;
    z-index: -1;
  }
  .design_linkbox{
    width: calc(100% / 3 - 4rem);
    margin: 2rem 0;
    display: inline-flex;
    position: relative;
    &:nth-child(1),&:nth-child(3){
      margin-top: 12rem;
    }
    &>p{
    position: absolute;
    bottom: 2rem;
    right: -1rem;
    font-weight: bold;
    font-size: 2rem;
    padding: 1rem 1.5rem;
    // backdrop-filter: blur(10px);
    border-radius: 15px;
    background: rgba(var(--navcolor), .85);
    color: #fff;
    box-shadow: 5px 5px 5px var(--shodowColor);
  }
  &:nth-child(2){
    p{
      bottom: 12rem;
    }
  }
  }
  .design_memo{
    position: absolute;
    top: -6rem;
    right: 50%;
    transform: translateX(50%);
    width: 90%;
    p{
      font-size: 1.5rem;
      text-align:center;
      span{
        color: red;
        .bigDate{
          font-weight: 700;
        }
      }
    }

    &::before{
      content: "";
    background: #000;
    width: 1px;
    height: 80%;
    position: absolute;
    top: 0;
    left: -1rem;
    transform: rotate(330deg);
    }
    &::after{
      content: "";
    background: #000;
    width: 1px;
    height: 65%;
    position: absolute;
    bottom: 15px;
    right: 0;
    transform: rotate(30deg);
    }
  }
}
.icon-plus {
  color: #fff;
  font-size: 2rem;
}
.design_box{
  transition: .5s;
  img{
    border-radius: 15px;
    box-shadow: 5px 5px 5px var(--shodowColor);
  }
  &:hover{
    transform: rotate3d(-5, 11, -1, 36deg);
  }
}
// 軟體開發區塊
.swier_bg {
  background: url(@/assets/soft_bg.png) bottom center no-repeat var(--seccolor);
  background-size: contain;
  padding: 0;
  height: 105vh;
  overflow: hidden;
  &.img1{
    background: url(@/assets/software_img.jpg) no-repeat;
    background-size: cover;
    position: relative;
    &::before{
      content: "";
      background: #000;
      opacity: .35;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .container-fuild{
    z-index: 1;
    position: inherit;
  }
  .swier_txt {
    padding: 5rem 0rem 3rem;
  }
  .swier_block{
    margin-bottom: 4rem;
    justify-content:space-evenly;
  }
  #soft_img{
    border-radius: 20px;
    background: url(@/assets/soft_comBg.jpg) bottom no-repeat;
    background-size: cover;
    box-shadow: 0px -5px 15px rgb(58 58 58 / 40%);
    transition: .5s;
    &.bg1{
      background: url(@/assets/software_img.jpg) bottom no-repeat;
      background-size: cover;
      transition: .5s;
    }
    &.bg2{
      background: url(@/assets/software_img2.jpg) bottom no-repeat;
      background-size: cover;
      transition: .5s;
    }
    &.bg3{
      background: url(@/assets/software_img3.jpg) bottom no-repeat;
      background-size: cover;
      transition: .5s;
    }
    &.bg4{
      background: url(@/assets/software_img4.jpg) bottom no-repeat;
      background-size: cover;
      transition: .5s;
    }
  }
}
.swier_box{
    font-size: 2rem;
    padding: 1rem 0;
    margin: 0 1rem;
    color: #fff;
    border-bottom: 0px solid #fff;
    text-align: center;
    transition: .3s;
    overflow:hidden;
    position: relative;
    &::before{
      content: "";
      width: 100%;
      height: 1px;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateX(-300px);
      transition: .3s;
      opacity: 0;
    }
    &:hover{
      &::before{
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

// 客製化系統開發
.cus_soft_bg{
  background: url(@/assets/cus_soft_bg.png) top right no-repeat;
  background-size: 80%;
  margin-top: 6rem;
  .cus_soft_img{
    position: relative;
    font-size: 7rem;
    color: var(--seccolor);
    i{
    z-index: 2;
    position: inherit;
    }
    &::before{
    content: "\e906";
    font-family: "icomoon";
    position: absolute;
    color: var(--thirdcolor);
    top: 1.5rem;
    right: -1.125rem;
    font-size: 6rem;
    opacity: 0.15;
    transform: rotate(330deg);
    }
  }
  .cus_soft_box{
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: .5s;
    &:hover{
      background: #fff;
      box-shadow: 5px 5px 5px var(--shodowColor);
      border-radius: 15px;
    }
    .title{
      font-size: 1.5rem;
      margin-top: 1rem;
      font-weight: 600;
    }
  }
}
.cus_soft_block{
  padding: 5rem 0rem 0;
}
// 年度成就區塊
.ach_bg {
  margin-top: 5rem;
  // min-height: 800px;
  position: relative;
  &::before{
    content: "\e900";
    font-family: 'icomoon';
    position: absolute;
    right: 13rem;
    top: 18%;
    font-size: 15rem;
    color: var(--maincolor);
    transform: rotate3d(1, 1, 1, 45deg);
    z-index:-1;
    opacity:.2;
  }
  &::after{
    content: "\e900";
    font-family: "icomoon";
    position: absolute;
    right: 1rem;
    top: 50%;
    font-size: 16rem;
    color: var(--seccolor);
    transform: rotate3d(7, -9, -1, 0.85turn);
    z-index: -1;
    opacity: 0.15;
  }
  .ach_flex{
    position: relative;
    &::before{
    content: "\e900";
    font-family: 'icomoon';
    position: absolute;
    right: 13rem;
    bottom: 2.5rem;
    font-size: 12rem;
    color: var(--thirdcolor);
    transform: rotate3d(1, 1, 3, 333deg);
    z-index:-1;
    opacity:.2;
  }
}
}
.his_box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  margin: 1rem;
  width: calc(100% / 2 - 2rem);
}
.his_box img {
  border-radius: 100%;
  max-width: 120px;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.his_box .his_title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.his_box span {
  color: #848484;
}
.his_title_block {
  max-width: 1600px;
  margin: 0 auto;
}
.his_txt_block {
  margin: 2rem 0 2rem 3rem;
  position: relative;
}
// 成功案例
.logo_block {
  display: flex;
  flex-wrap: wrap;
}
.logo_box {
  width: calc(100% / 5 - 3rem);
  margin: 1.5rem 1.5rem;
}

/* RESPONSIVE */
@media screen and (min-width:2560px){
  .his_img_block{
    width: 40%;
  }
}
@media screen and (max-width: 1400px) {
  .scroll_space{
    height: 150vh;
  }
  .his_txt_block {
    margin: 1rem 0;
    padding: 0 1rem;
  }
  .his_txt_block {
    margin-top: 2rem;
  }
  .web_title .en_title{
    width: 32%;
  }
  .circle_bg .circle{
    bottom: 30%;
  }
  .circle_bg .circle_txt{
    bottom:39%;
    left: 14%;
  }
  .web_txt{
    right: -90px;
    width: 45%;
    bottom: 23%;
    padding: 2rem 3rem;
    background-size: cover;
  }
  .web_Bg .design_linkbox > p{
    font-size: 1.5rem;
  }
  .web_Bg .design_memo{
    top: -8rem;
  }
  .web_Bg .container::before{
    font-size: 7rem;
  }
  /*軟體專案開發*/
  .swier_bg .swier_txt{
    padding:0;
  }
  .swier_txt{
    .en_title{
      width: 75%;
    }
  }
  .cus_soft_block{
    width: 100%;
  }
  .cus_soft_bg{
    .main_title{
      width: 30%;
    }
  }
  // 歷史成就
  .his_title_block{
    padding: 0 7rem;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .swier_bg {
    min-height: 500px;
    padding-top: 6rem;
  }
  .his_title_block {
    max-width: 1400px;
    padding: 0 7rem;
  }
  .circle_bg{
    min-height: 350px;
    .circle_bg .circle_txt{
      bottom: 31%;
    left: 14%;
    }
  }
}
@media screen and (max-width: 1199px) {
  .scroll_space{
    height: 100vh;
  }
  // 網頁設計區塊
  .web_title{
    position: unset;
  }
  .web_title .en_title{
    width: 100%;
  }
  .circle_bg{
    margin: 6.5rem auto 0;
    min-height: 300px;
  }
  .web_txt{
    right: 0;
    width: 100%;
    bottom: -75%;
    padding: 2rem 3rem;
    background: #fff;
    box-shadow: 0px 5px 5px var(--shodowColor);
  }
  .web_Bg{
    margin-top: 35rem;
  }
  .circle_bg .circle_txt{
    bottom: 29%;
    left: 21%;
  }
  .web_Bg .design_linkbox{
    width: calc(100% / 2 - 2rem);
  }
  .web_Bg .container::before{
    left: 20%;
  }
  .design_linkbox:nth-child(3){
    margin-left: 16rem;
    margin-top: 10rem;
  }
  // 軟體專案
  .swier_bg{
    padding: 5rem 0px 0px;
    height: 70vh;
    min-height: 1000px;
  }
  .swier_bg .swier_box{
    margin: 0;
  }
  .swier_box{
    position: relative;
    text-align:left;
    &::before{
      opacity: 0;
    }
    &::after{
      font-family: "icomoon";
    content: "\e90b";
    font-size: 1.5rem;
    position: absolute;
    right: 6rem;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%);
    }
  }
  .swier_block{
    flex-wrap: wrap;
  }
  .swier_bg .swier_box{
    margin: 0rem 1rem 1rem;
    width: calc(100% / 2 - 2rem);
  }
  .cus_soft_bg .main_title{
    width: 100%;
  }
  .cus_soft_bg{
    background-position: top left;
    background-size: cover;
  }
  .cus_soft_bg{
    padding-top: 8rem;
  }
  // 年度成就
  .his_title_block{
    padding: 0;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .his_title_block {
    max-width: 960px;
  }
  .his_img_block {
    display: flex;
    justify-content: center;
  }
  .circle_bg{
    &::before{
      bottom: -77px;
    }
    .circle{
      width: 25%;
    bottom: 12%;
    }
  }
}
@media screen and (max-width: 991px) {
// 網站設計區塊
.circle_bg .circle_txt{
    bottom: 40%;
    left: 15%;
  }
  .web_Bg{
    margin-top: 20rem;
    &::before{
      font-size: 6rem;
    }
    &::after{
      font-size: 5rem;
      top: 36rem;
    }
  }
  .web_Bg .design_linkbox:nth-child(2) p{
    bottom: 2rem;
  }
  .web_Bg .design_linkbox:nth-child(1), .web_Bg .design_linkbox:nth-child(3){
    margin-top: 10rem;
  }
  .web_Bg .container::before{
    font-size: 5rem;
  }
  .web_txt{
    bottom: -45%;
  }
  .web_Bg{
    margin-top: 20rem;
    .container::before{
      left: unset;
    right: 0;
    font-size: 6rem;
    }
    &::before{
      font-size: 7rem;
    }
    &::after{
      top: 38rem;
    font-size: 7rem;
    }
  }
  .design_linkbox:nth-child(3){
    margin-left: 12rem;
    margin-top: 10rem;
  }
  // 軟體開發
  .swier_bg{
    height: 100vh;
  }
  .swier_txt .en_title{
    width: 100%;
  }
  .swier_box{
    &::after{
      right: 0;
    }
  }
  .cus_soft_bg .cus_soft_img{
    display: flex;
    justify-content: center;
    &::before{
      right: 0rem;
    }
  }
  // 年度成就
.his_title_block{
  padding: 1rem;
}
// 成功案例
.logo_box{
  width: calc(100% / 3 - 2rem);
  margin: 1.5rem 1rem;
}
}

@media screen and (max-width: 767px) {
  .scroll_space{
    height: 80vh;
  }
  .circle_bg{
    min-height: 380px;
  }
  .circle_bg .circle{
    bottom: 55%;
    width: 30%;
  }
  .circle_bg .circle_txt{
    bottom: 63%;
    left: 21%;
  }
  .web_txt{
    bottom: -25%;
    padding: 2rem 2rem;
  }
  .web_Bg{
    margin-top: 14rem;
    margin-bottom: 4rem;
    &::before,&::after{
      line-height:1.25;
      overflow-wrap: anywhere;
    }
    &::before{
      top: 0;
      font-size: 6rem;
    }
    .container{
      &::before{
        line-height:1.25;
        bottom: -4rem;
      }
    }
    .design_memo{
      top: -6rem;
      width: 85%;
    }
  }
  .web_Bg .design_linkbox{
    width: 100%;
    margin: 1rem 0;
    padding: 0 1rem;
    &> p{
      right: 0;
    }
  }
  .design_linkbox:nth-child(3){
    margin-left: 0;
  }
  .web_Bg .design_linkbox:nth-child(1), .web_Bg .design_linkbox:nth-child(3){
    margin-top: 9rem;
  }
  .design_box:hover{
    transform:unset;
  }
// 軟體
.swier_bg{
  height: 120vh;
  .swier_box{
    width: 100%;
    font-size: 1.5rem;
    padding: 0.5rem 0;
  }
}

.cus_soft_bg{
  background: #f2f2f2;
  border-radius: 30px;
  position: relative;
  .container{
    position: relative;
    &::before{
    content: "";
    width: 2rem;
    height: 2rem;
    background: #bababa;
    position: absolute;
    top: -6rem;
    left: 8rem;
    border-radius: 50%;
    }
    &::after{
    content: "";
    width: 90%;
    height: 1px;
    background: #bababa;
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    }
  }
  &::before{
    content: "";
    width: 2rem;
    height: 2rem;
    background: #bababa;
    position: absolute;
    top: 2rem;
    left: 2rem;
    border-radius: 50%;
  }
  &::after{
    content: "";
    width: 2rem;
    height: 2rem;
    background: #bababa;
    position: absolute;
    top: 2rem;
    left: 5rem;
    border-radius: 50%;
  }
}
.cus_soft_block{
  padding: 1rem 0rem 0;
}
// 年度成就
.his_box{
  margin: 1rem 0;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}
.ach_bg {
  &::before{
    display: none;
  }
  &::after{
    display: none;
  }
}
.ach_bg .ach_flex::before{
  display: none;
}
// 成功案例
.logo_box{
  width: calc(100% / 2 - 2rem);
  &:nth-child(n+11):nth-child(-n+15){
    display: none;
  }
}

}
@media screen and (max-width:375px) {
  .circle_bg .circle{
    bottom: 65%;
    width: 25%;
  }
  .circle_bg .circle_txt{
    bottom: 70%;
    left: 18%;
    font-size: 18px;
  }
  .web_Bg .design_memo{
    width: 90%;
    &::before{
      left: 0rem;
    }
  }
  .swier_bg{
    min-height: 1070px;
  }
  .web_Bg .design_memo p{
    font-size: 1.25rem;
    padding: 0 0.5rem;
  }
}
</style>
